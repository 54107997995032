@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  *,
  a,
  input,
  button {
    font-family: 'Poppins', sans-serif;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: #000000 !important;
    -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
  }
}

html {
  scroll-behavior: smooth;
  scrollbar-width: thin;
}

::-webkit-scrollbar {
 width: 4px;
 height: 4px;
}

::-webkit-scrollbar-track {
-webkit-box-shadow: inset 0 0 6px rgb(33, 63, 125, 0.3);
border-radius: 10px;
}

::-webkit-scrollbar-thumb {
border-radius: 10px;
-webkit-box-shadow: inset 0 0 6px #7B7B7B;
}

body {
  background: #FFFEF1;
}

a {
  list-style: none;
  text-decoration: none;
  font-size: 16px;
}

.link-active {
  font-weight: 600;
}
.link-active:after {
  content: "";
  display: block;
  width: 24px;
  padding-top: 3px;
  border-bottom: 3px solid #ffc42b;
}
.link:hover::after {
  content: "";
  font-weight: 600;
  display: block;
  width: 24px;
  padding-top: 3px;
  border-bottom: 3px solid #ffc42b;
}

.reviews {
  max-height: 70vh;
  overflow-y: scroll;
}

/* Scroller styling */
.scroller {
  height: 1.2em;
  line-height: 1.2em;
  position: relative;
  overflow: hidden;
  flex-basis: 1;
  width: 120px;
}
.scroller > span {
  position: absolute;
  top: 0;
  animation: slide 6s infinite;
  animation-direction: alternate;
  animation-fill-mode: forwards;
  font-weight: bold;
}
@keyframes slide {
  0% {
    top: 0;
  }
  25% {
    top: -1.2em;
  }
  50% {
    top: -2.4em;
  }
  75% {
    top: -3.6em;
  }
}

.curve-underline::after {
  content: '';
  width: 100%;
  height: 15px;
  background-image: url(./assets/line.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  bottom: -5px;
  left: 0;

  @include sm {
    height: 8px;
  }
}

.toast {
  animation: toast .5s forwards;
}

@keyframes toast {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.modal-scroll::-webkit-scrollbar{
  display: none;
}


.radio-button {
  appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border: 2px solid #7B7B7B; 
  background-color: transparent;
  cursor: pointer;
}

.radio-button:checked {
  background-color: #FE724E;
  border-color: #FE724E;
}


.radio-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(254, 114, 78, 0.5);
}
.text-nowrap {
  text-wrap: nowrap;
}